<template>
  <div>
    <div v-if="processingRefresh">
      <span class="ml-2 text-white text-sm">Refreshing...</span>
    </div>
    <div
      v-else
      class="bg-theme-16 cursor-pointer shadow-md bottom-0 box shadow-md border mt-4 w-40 h-8 flex items-center justify-center p-4"
      @click="refreshHemCalculations"
    >
      <div class="ml-1">
        <RefreshCcwIcon class="w-4 h-4 side-menu__icon text-white" />
        <span class="ml-2 text-white text-sm">Refresh HEM</span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  props: {
    applicationId: {
      type: Number,
      require: false
    }
  },
  setup(props) {
    const store = useStore();

    // const { buildSwalWarning } = customSwalMessageBuilder();

    const processingRefresh = computed(
      () => store.state.loanCalculations.refreshingCalculations
    );

    function refreshHemCalculations() {
      store.dispatch("loanCalculations/refreshHemCalculations", {
        application_id: props.applicationId
      });
    }
    return {
      processingRefresh,
      refreshHemCalculations
    };
  }
});
</script>
