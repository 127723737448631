<template>
  <div v-if="showLoanInfo">
    <div
      :class="[
        { 'bg-theme-32': isActive },
        `loan-${getActiveApplicationQualityClass()}`
      ]"
      class="flex mt-1 p-4 box cursor-pointer section-header"
      @click="setNavActiveSection('application-detail-title')"
    >
      <h2
        id="application-detail-title"
        class="font-medium text-theme-7 text-base w-full"
      >
        APPLICATION DETAIL
      </h2>
      <ChevronDownIcon :class="{ 'transform rotate-180': !isActive }" />
    </div>
    <div
      v-if="isActive"
      id="application-dts-section"
      class="grid grid-cols-12 gap-6"
    >
      <div v-if="!fetchingKYC" class="col-span-12">
        <div class="box">
          <div class="p-5">
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-6 p-5">
                <h4 class="font-medium text-theme-3 text-base mb-5">
                  Loan Information
                  <span
                    v-if="fetchingLnSysAutoDecisions"
                    class="text-xs px-1 rounded-full border bg-black bg-opacity-50 text-theme-2 dark:border-darkmode-100/40 dark:text-slate-300 mr-1"
                  >
                    Fetching Automated Decisions ...
                  </span>
                  <span
                    v-if="
                      automatedDecisions && automatedDecisions.is_auto_declined
                    "
                    class="text-xs px-1 rounded-full border bg-theme-11 text-theme-2 dark:border-darkmode-100/40 dark:text-slate-300 mr-1"
                  >
                    <i class="icon"></i> Auto Declined</span
                  >
                  <span
                    v-if="
                      automatedDecisions && automatedDecisions.is_auto_completed
                    "
                    class="text-xs px-1 rounded-full border bg-theme-10 text-theme-2 dark:border-darkmode-100/40 dark:text-slate-300 mr-1"
                    >Auto Completed</span
                  >
                </h4>
                <div class="grid grid-cols-12">
                  <label
                    class="form-label col-span-3 mt-1.5"
                    for="ln-assess-application-id"
                    >Application ID</label
                  >
                  <input
                    id="ln-assess-application-id"
                    :value="applicationId"
                    class="form-control col-span-6 gap-3"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
                <div class="mt-3 grid grid-cols-12">
                  <label
                    class="form-label col-span-3 mt-1.5"
                    for="ln-assess-sub-date"
                    >Submission Date</label
                  >
                  <input
                    id="ln-assess-sub-date"
                    :value="loan.created_at"
                    class="form-control col-span-6 gap-3"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
                <div class="mt-3 grid grid-cols-12">
                  <label
                    class="form-label col-span-3 mt-1.5"
                    for="ln-assess-ln-amt"
                    >Applied Amount</label
                  >
                  <input
                    id="ln-assess-ln-amt"
                    :value="
                      loan.requested_loan_amount
                        ? loan.requested_loan_amount
                        : loan.total_amount
                    "
                    class="form-control col-span-6 gap-3"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
                <div class="mt-3 grid grid-cols-12">
                  <label
                    class="form-label col-span-3 mt-1.5"
                    for="ln-assess-pay-freq"
                    >Repayment Amount</label
                  >
                  <input
                    id="ln-assess-repay-amount"
                    :value="loan.repayment_amount"
                    class="form-control col-span-6 gap-3"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
                <div class="mt-3 grid grid-cols-12">
                  <label
                    class="form-label col-span-3 mt-1.5"
                    for="ln-assess-pay-freq"
                    >Payment Frequency</label
                  >
                  <input
                    id="ln-assess-pay-freq"
                    :value="loan.repayment_frequency"
                    class="form-control col-span-6 gap-3"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
                <div class="mt-3 grid grid-cols-12">
                  <label
                    class="form-label col-span-3 mt-1.5"
                    for="ln-assess-no-rep"
                    >No. of Repayments</label
                  >
                  <input
                    id="ln-assess-no-rep"
                    :value="loan.repayments"
                    class="form-control col-span-6 gap-3"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
                <div class="mt-3 grid grid-cols-12">
                  <label
                    class="form-label col-span-3 mt-1.5"
                    for="ln-assess-no-rep"
                    >Loan Reason</label
                  >
                  <input
                    id="ln-reason"
                    :value="
                      getKycAttributeValueByName('Reason_1_for_Loan') +
                        (getKycAttributeValueByName('Sub_Reason_1_for_Loan') &&
                        getKycAttributeValueByName(
                          'Sub_Reason_1_for_Loan'
                        ).replace('-', '').length
                          ? `: ${getKycAttributeValueByName(
                              'Sub_Reason_1_for_Loan'
                            )} `
                          : '')
                    "
                    class="form-control col-span-6 gap-3"
                    disabled
                    placeholder="Input text"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-span-12 xl:col-span-6 p-5">
                <h4 class="font-medium text-theme-3 text-base mb-5">
                  Discretionary Questionnaire
                </h4>
                <div class="grid grid-cols-12 mb-2">
                  <label class="form-label col-span-6 mt-1.5"
                    >Are you willing to lower recreational spending?</label
                  >
                  <div
                    class="
                      flex
                      border-2 border-transparent
                      items-center
                      rounded-md
                      ml-5
                    "
                    @click="
                      getKycAttributeValueByName(
                        'Are_you_willing_to_lower_recreational_spending_o'
                      ) !== 'no' &&
                        onClickTransformToInput(
                          'Are_you_willing_to_lower_recreational_spending_o'
                        )
                    "
                  >
                    <p class="qa_response">
                      {{
                        getKycAttributeValueByName(
                          "Are_you_willing_to_lower_recreational_spending_o"
                        )
                      }}
                    </p>
                    <Edit3Icon
                      v-if="
                        getKycAttributeValueByName(
                          'Are_you_willing_to_lower_recreational_spending_o'
                        ) !== 'no'
                      "
                      class="w-4 ml-2"
                    />
                  </div>
                  <div
                    v-if="
                      activeField ===
                        'Are_you_willing_to_lower_recreational_spending_o'
                    "
                    class="col-span-3"
                  >
                    <select
                      v-model="discretionaryQASelection"
                      aria-label=".form-select-sm example"
                      class="form-select form-select-sm mt-2 w-3/5"
                    >
                      <option
                        v-for="(opt, i) in discretionaryQuestionsFlags"
                        :key="i"
                        :value="opt"
                      >
                        {{ opt }}
                      </option>
                    </select>
                    <span
                      v-if="
                        activeField ===
                          'Are_you_willing_to_lower_recreational_spending_o'
                      "
                      @click="
                        onCloseAndSubmitQAattrs(
                          discretionaryQASelection,
                          'Are_you_willing_to_lower_recreational_spending_o',
                          applicationId
                        )
                      "
                    >
                      <CheckCircleIcon
                        class="ml-2 hover:text-blue-500 h-5 w-5"
                      />
                    </span>

                    <XCircleIcon
                      v-if="
                        activeField ===
                          'Are_you_willing_to_lower_recreational_spending_o'
                      "
                      class="ml-2 hover:text-blue-500 h-5 w-5"
                      @click="onCloseKycSelectBox"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-12 mb-2">
                  <label class="form-label col-span-6 mt-1.5"
                    >Do you foresee your financial situation changing?</label
                  >
                  <div
                    class="
                      flex
                      border-2 border-transparent
                      items-center
                      rounded-md
                      ml-5
                    "
                    @click="
                      getKycAttributeValueByName(
                        'Do_you_foresee_your_financial_situation_changing_o'
                      ) !== 'no' &&
                        onClickTransformToInput(
                          'Do_you_foresee_your_financial_situation_changing_o'
                        )
                    "
                  >
                    <p class="qa_response">
                      {{
                        getKycAttributeValueByName(
                          "Do_you_foresee_your_financial_situation_changing_o"
                        )
                      }}
                    </p>
                    <Edit3Icon
                      v-if="
                        getKycAttributeValueByName(
                          'Do_you_foresee_your_financial_situation_changing_o'
                        ) !== 'no'
                      "
                      class="w-4 ml-2"
                    />
                  </div>
                  <div
                    v-if="
                      activeField ===
                        'Do_you_foresee_your_financial_situation_changing_o'
                    "
                    class="col-span-3"
                  >
                    <select
                      v-model="discretionaryQASelection"
                      aria-label=".form-select-sm example"
                      class="form-select form-select-sm mt-2 w-3/5"
                    >
                      <option
                        v-for="(opt, i) in discretionaryQuestionsFlags"
                        :key="i"
                        :value="opt"
                      >
                        {{ opt }}
                      </option>
                    </select>
                    <span
                      @click="
                        onCloseAndSubmitQAattrs(
                          discretionaryQASelection,
                          'Do_you_foresee_your_financial_situation_changing_o',
                          applicationId
                        )
                      "
                    >
                      <CheckCircleIcon
                        class="ml-2 hover:text-blue-500 h-5 w-5"
                      />
                    </span>
                    <XCircleIcon
                      class="ml-2 hover:text-blue-500 h-5 w-5"
                      @click="onCloseKycSelectBox"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-12 mb-2">
                  <label class="form-label col-span-6 mt-1.5"
                    >Do you have any loans rental in any other finance?</label
                  >
                  <div
                    class="
                      flex
                      border-2 border-transparent
                      items-center
                      rounded-md
                      ml-5
                    "
                    @click="
                      getKycAttributeValueByName(
                        'Do_you_have_any_loans_rental_any_other_finance_o'
                      ) !== 'no' &&
                        onClickTransformToInput(
                          'Do_you_have_any_loans_rental_any_other_finance_o'
                        )
                    "
                  >
                    <p class="qa_response">
                      {{
                        getKycAttributeValueByName(
                          "Do_you_have_any_loans_rental_any_other_finance_o"
                        )
                      }}
                    </p>
                    <Edit3Icon
                      v-if="
                        getKycAttributeValueByName(
                          'Do_you_have_any_loans_rental_any_other_finance_o'
                        ) !== 'no'
                      "
                      class="w-4 ml-2"
                    />
                  </div>
                  <div
                    v-if="
                      activeField ===
                        'Do_you_have_any_loans_rental_any_other_finance_o'
                    "
                    class="col-span-3"
                  >
                    <select
                      v-model="discretionaryQASelection"
                      aria-label=".form-select-sm example"
                      class="form-select form-select-sm mt-2 w-3/5"
                    >
                      <option
                        v-for="(opt, i) in discretionaryQuestionsFlags"
                        :key="i"
                        :value="opt"
                      >
                        {{ opt }}
                      </option>
                    </select>
                    <span
                      @click="
                        onCloseAndSubmitQAattrs(
                          discretionaryQASelection,
                          'Do_you_have_any_loans_rental_any_other_finance_o',
                          applicationId
                        )
                      "
                    >
                      <CheckCircleIcon
                        class="ml-2 hover:text-blue-500 h-5 w-5"
                      />
                    </span>
                    <XCircleIcon
                      class="ml-2 hover:text-blue-500 h-5 w-5"
                      @click="onCloseKycSelectBox"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-12 mb-2">
                  <label class="form-label col-span-6 mt-1.5"
                    >Do you have partner who shares expenses?</label
                  >
                  <div
                    class="
                      flex
                      border-2 border-transparent
                      items-center
                      rounded-md
                      ml-5
                    "
                    @click="
                      getKycAttributeValueByName(
                        'partner_who_shares_expenses'
                      ) !== 'no' &&
                        onClickTransformToInput('partner_who_shares_expenses')
                    "
                  >
                    <p class="qa_response">
                      {{
                        getKycAttributeValueByName(
                          "partner_who_shares_expenses"
                        )
                      }}
                    </p>
                    <Edit3Icon
                      v-if="
                        getKycAttributeValueByName(
                          'partner_who_shares_expenses'
                        ) !== 'no'
                      "
                      class="w-4 ml-2"
                    />
                  </div>
                  <div
                    v-if="activeField === 'partner_who_shares_expenses'"
                    class="col-span-3"
                  >
                    <select
                      v-model="discretionaryQASelection"
                      aria-label=".form-select-sm example"
                      class="form-select form-select-sm mt-2 w-3/5"
                    >
                      <option
                        v-for="(opt, i) in discretionaryQuestionsFlags"
                        :key="i"
                        :value="opt"
                      >
                        {{ opt }}
                      </option>
                    </select>
                    <span
                      @click="
                        onCloseAndSubmitQAattrs(
                          discretionaryQASelection,
                          'partner_who_shares_expenses',
                          applicationId
                        )
                      "
                    >
                      <CheckCircleIcon
                        class="ml-2 hover:text-blue-500 h-5 w-5"
                      />
                    </span>

                    <XCircleIcon
                      class="ml-2 hover:text-blue-500 h-5 w-5"
                      @click="onCloseKycSelectBox"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-12 mb-2">
                  <label class="form-label col-span-6 mt-1.5"
                    >Are you willing to accept higher loan amount?</label
                  >
                  <div
                    class="
                      flex
                      border-2 border-transparent
                      items-center
                      rounded-md
                      ml-5
                    "
                  >
                    <p class="qa_response">
                      {{
                        getKycAttributeValueByName(
                          "Are_you_willing_to_accept_a_higher_amount_of_loan"
                        )
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-12 gap-x-5">
              <div class="col-span-12 xl:col-span-12 p-5">
                <h4
                  v-if="loanMetaAttributesTransformed.length > 0"
                  class="font-medium text-theme-3 text-base mb-5"
                >
                  Loan Meta Information
                </h4>
                <div v-if="loanMetaAttributesTransformed.length > 0" class="">
                  <div
                    :style="{
                      gridTemplateRows: `repeat(${Math.ceil(
                        loanMetaAttributesTransformed.length / 2
                      )}, auto)`
                    }"
                    class="loan-meta-items-container"
                  >
                    <template
                      v-for="(addAttr,
                      addAttrIndex) in loanMetaAttributesTransformed"
                      :key="addAttrIndex"
                    >
                      <div class="flex">
                        <label class="form-label w-1/2"
                          >{{
                            checkMatchingLastChars(
                              2,
                              addAttr.attr
                                .split("_")
                                .map(
                                  w => w.charAt(0).toUpperCase() + w.slice(1)
                                )
                                .join(" "),
                              " o"
                            )
                          }}
                        </label>
                        <div class="w-1/2">
                          <p class="qa_response">
                            {{ loanMetaAttrValueTransformer(addAttr) }}
                          </p>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FetchingRecordDivTemplate v-else marginTop="0" />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { customerAndLoanDetails } from "@/composables/components/customerAndLoanDetails";
import FetchingRecordDivTemplate from "@/components/fetching-record-div-template/Main";
import { loanQuality } from "@/composables/loanQuality";

export default defineComponent({
  components: {
    FetchingRecordDivTemplate
  },
  props: {
    applicationId: {
      type: Number,
      require: true,
      default: null
    },
    showLoanInfo: {
      type: Boolean,
      require: false,
      default: true
    },
    setNavActiveSection: {
      type: Function,
      require: true,
      default: null
    },
    isActive: {
      type: Boolean,
      default: false
    },
    activeField: {
      type: String,
      default: "",
      require: true
    }
  },
  setup(props) {
    const discretionaryQuestionsFlags = ["Yes", "No"];
    const discretionaryQASelection = ref("Yes");
    const {
      fetchingKYC,
      checkMatchingLastChars,
      userAttrsToOmitOnMeta,
      kycWithAdditionalAttributes,
      loanMetaAttributesForAssessment,
      getKycAttributeValueByName,
      loanMetaAttrValueTransformer,
      loan,
      fetchingLnSysAutoDecisions,
      automatedDecisions,
      onCloseKycSelectBox,
      onCloseAndSubmitQAattrs,
      onClickTransformToInput
    } = customerAndLoanDetails(props, false);
    const transformMetaAttribute = {
      capitalizeFirstLetter: function(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      reorder: function(loanMetaAttributeCollection) {
        const order = [
          "bank",
          "Payslip_fluctuate",
          "Centerlink_increase_continue",
          "Wage_expect_unchanged",
          "Wage_expect_change_direction",
          "Wage_expect_change_reason"
        ];
        return loanMetaAttributeCollection.sort((a, b) => {
          const indexA = order.indexOf(a.attr);
          const indexB = order.indexOf(b.attr);
          if (indexA === -1 && indexB === -1) {
            return 0;
          } else if (indexA === -1) {
            return 1;
          } else if (indexB === -1) {
            return -1;
          } else {
            return indexA - indexB;
          }
        });
      },
      transformIncomeDetails: function(loanMetaAttributeCollection) {
        const incomeType = getKycAttributeValueByName("Income_Type");
        const isIncomeWageOnly = incomeType.toLowerCase() === "wages";
        const isIncomeCenterlinkOnly =
          incomeType.toLowerCase() === "centrelink only";
        const isIncomeWageAndCenterlink =
          incomeType.toLowerCase() === "wages & centerlink";
        let transformedLoanMetaAttributeCollection = loanMetaAttributeCollection;
        if (isIncomeWageOnly || isIncomeWageAndCenterlink) {
          if (isIncomeWageOnly) {
            transformedLoanMetaAttributeCollection = transformedLoanMetaAttributeCollection.filter(
              attr => attr.attr !== "Centerlink_increase_continue"
            );
          }
          let isWageExpectUnchanged =
            transformedLoanMetaAttributeCollection
              .find(attr => attr.attr === "Wage_expect_unchanged")
              ?.value.toLowerCase() === "yes";
          transformedLoanMetaAttributeCollection = transformedLoanMetaAttributeCollection.filter(
            attr =>
              !(
                isWageExpectUnchanged &&
                [
                  "Wage_expect_change_reason",
                  "Wage_expect_change_direction"
                ].includes(attr.attr)
              )
          );
          transformedLoanMetaAttributeCollection = transformedLoanMetaAttributeCollection.map(
            attr => {
              if (attr.attr === "Wage_expect_unchanged") {
                attr.attr = "Will_wage_remain_unchanged";
                attr.value = this.capitalizeFirstLetter(attr.value);
              } else if (attr.attr === "Wage_expect_change_direction") {
                attr.attr = "Will_wage_increase_or_decrease";
                attr.value = this.capitalizeFirstLetter(attr.value);
              } else if (attr.attr === "Wage_expect_change_reason") {
                attr.attr = "Reason_for_wage_change";
              }
              return attr;
            }
          );
        }
        if (isIncomeCenterlinkOnly || isIncomeWageAndCenterlink) {
          if (isIncomeCenterlinkOnly) {
            transformedLoanMetaAttributeCollection = transformedLoanMetaAttributeCollection.filter(
              attr =>
                ![
                  "Wage_expect_unchanged",
                  "Wage_expect_change_direction",
                  "Wage_expect_change_reason"
                ].includes(attr.attr)
            );
          }
          transformedLoanMetaAttributeCollection = transformedLoanMetaAttributeCollection.map(
            attr => {
              if (attr.attr === "Centerlink_increase_continue") {
                attr.attr = "Will_centerlink_amount_continue_to_increase";
                attr.value = this.capitalizeFirstLetter(attr.value);
              }
              return attr;
            }
          );
        }
        return transformedLoanMetaAttributeCollection;
      }
    };
    const loanMetaAttributesTransformed = computed(() => {
      const original = loanMetaAttributesForAssessment.value?.original || [];
      const derived = loanMetaAttributesForAssessment.value?.derived || [];
      let combined = original.concat(derived);
      combined = transformMetaAttribute.reorder(combined);
      combined = transformMetaAttribute.transformIncomeDetails(combined);
      return combined;
    });

    const { getActiveApplicationQualityClass } = loanQuality();

    return {
      fetchingKYC,
      checkMatchingLastChars,
      userAttrsToOmitOnMeta,
      kycWithAdditionalAttributes,
      loanMetaAttributesTransformed,
      getKycAttributeValueByName,
      loanMetaAttrValueTransformer,
      loan,
      fetchingLnSysAutoDecisions,
      automatedDecisions,
      onCloseKycSelectBox,
      onCloseAndSubmitQAattrs,
      discretionaryQuestionsFlags,
      discretionaryQASelection,
      onClickTransformToInput,
      getActiveApplicationQualityClass
    };
  }
});
</script>
<style lang="scss" scoped>
.qa_response::first-letter {
  text-transform: capitalize;
}

.loan-meta-items-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  grid-gap: 15px;
}
</style>
